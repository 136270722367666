.activity-edit-modal {

    @media (max-height: 768px) {
        form {
            margin-bottom: 4rem !important;
        }

        .modal-footer {
            display: flex;
            margin-bottom: 0 !important;

            .footer-row {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: flex-end;
                padding: 0;

                .modal-action {
                    display: flex;
                    width: auto;
                    padding: 0;
                    position: fixed;
                    bottom: 0.5rem;
                    left: 1.5rem;
                }

                .detail-button {
                    display: flex;
                    justify-content: flex-end;

                }

            }
        }
    }
}