.table-wrapper{
    display: flex;
    flex-direction: column;
    max-height: 70vh;
    padding-bottom: 1rem;
    justify-content: space-between;
    overflow: auto;

.programViewTable {
    width: 100%;
    table-layout: fixed;
    font-size: 12px !important;
    overflow: auto;

    @media (max-width: 1024px) {
        font-size: 8px !important;
        
    }

    @media (max-width: 700px) {
        display: block;
        height: 80vh;
    }


    & thead tr th {
        background-color: #364042;
    }

    & tr {
        & .includeFloating {
            background-color: rgba(234, 84, 85, 0.2);
        }


    }

    & tbody{
        tr{
            height: 90px;
        }
    }
    & tbody:last-child {
        border-bottom: 1px solid #4B4B4B;

        & td:first-child {
            border-bottom-left-radius: 0 !important;
        }
    }

    & th {
        min-width: auto;
        font-size: 12px !important;
        padding: 4px 2px;
        position: sticky;
        top: 0;

        @media (max-width: 768px) {
            font-size: 8px !important;
        }

        @media (min-width: 768px) and (max-width: 1024px) {
            font-size: 10px !important;
        }

        &>div {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: wrap !important;
            text-align: center;
            overflow-wrap: anywhere;
        }
    }

    & td {
        min-width: 60px !important;
        min-height: 60px !important;
        width: auto;
        height: auto;
        box-sizing: border-box;
        cursor: pointer;
        vertical-align: middle;

        .box-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            text-align: center;
            box-sizing: border-box;
            word-break: keep-all;
        }

        &:first-child {
            vertical-align: middle;
            text-align: center;
            font-weight: 600;
            cursor: text;
            box-sizing: border-box;
        }

        & .trainer-cell {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            white-space: pre-wrap;
            text-align: center;
        }



        &.bg-light-group {
            background-color: rgba(255, 234, 0, 0.12);
            color: #ffff00 !important;
        }

        &.bg-light-person {
            background-color: rgba(228, 161, 27, 0.12);
            color: #E4A11B !important;
        }

        &.bg-light-danger {
            background-color: rgb(234, 84, 85, 0.12);
            color: #ea5455 !important;
        }

        &.bg-light-primary {
            background-color: rgb(115, 103, 240, 0.12);
            color: #7367f0 !important;
        }

        &.bg-light-success {
            background-color: rgb(40, 199, 111, 0.12);
            color: #28c76f !important;
        }

        &.bg-light-info {
            background-color: rgb(0, 207, 232, 0.12);
            color: #00cfe8 !important;
        }

        &.bg-light-warning {
            background-color: rgba(228, 161, 27, 0.12);
            color: #ff9f43 !important;
        }
    }
}
}
.div-scroll {
    min-height: 60vh;
    max-height: 90vh;

}