@import '../../@core/scss/base/bootstrap-extended/include'; 

$fc-sidebar-width: 26rem;

.calendar-flat{
    display: none;
    left: 0;
}

.app-calendar {
    .app-calendar-sidebar { 
        height: 100% !important;
        max-width: 350px !important;
        width: $fc-sidebar-width !important;
            left: calc(-#{$fc-sidebar-width} - 5.0rem) !important;
            flex-basis: $fc-sidebar-width !important;
            &.show {
                left: 0 !important;
            }
    }
      & .position-relative {
        // overflow: auto;
        & .fc-view-harness {
            height: 100% !important;
            width: 100% !important;
            margin: 0 !important;

            .fc-more-popover{
                      max-height: 300px !important;
                      overflow-y: scroll !important;
            }

            // & .fc-day-today {
            //     background: unset !important;
            // } 
        }
       
        & .fc-header-toolbar {
            & .fc-toolbar-chunk{
                & .fc-button-group{
                    & .fc-button{
                        @include media-breakpoint-down(md) {
                            padding: 0.438rem 0.5rem;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 992px) {
        .app-calendar-sidebar {
          position: absolute !important;
        }
      }
}


@media (min-width: 992px) {
    .fc {
      .fc-sidebarToggle-button {
        display: block !important;
      }
    }
  }
  

  .flatpickr-wrapper{
    width: 100%;
  }

  .activityHistoryTable {
    background: rgba(22, 39, 49, 0.5);
    border-radius: 1.1rem !important;
    margin: 8px 0;
    & .table > :not(caption) > * > * {
        padding: 1rem !important;
    }
}