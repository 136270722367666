
@import '../bootstrap-extended/include'; // Bootstrap includes
@import '../components/include'; // Components includes


.footerButtonGroup{
    display: flex;
    justify-content: center;
    padding-top: 13.25rem;
}
.info-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}
.securityTitle{
    border-bottom: 1px solid #4B4B4B;
    padding-bottom: 17px;
}
.textWarning{margin-top: 21px;}
.recentDevicesTable{
    background-color: #364042 !important;
}
.contentsTab{
    width: 70px;
}