
@import '../bootstrap-extended/include'; // Bootstrap includes
@import '../components/include'; // Components includes


.forgotPassword{
   color: $orange-link;
    :hover{
        color: $warning !important;
    }
}
.auth-wrapper.auth-basic .auth-inner:before{
    left: -172px ;
    top: -105px ;
}
.auth-wrapper.auth-basic .auth-inner:after{
    right: -198px ;
    bottom: -158px ;
}
.rememberMe{
    color: $text-muted !important;
}

.login-footer{
    display: flex;
    width: 100%;
    justify-content: end;
    text-decoration: underline;
    cursor: pointer;
}