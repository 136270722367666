
  @import '../../../@core/scss/base/bootstrap-extended/include';
  @import '../../../@core/scss/base/components/include';
  .react-tel-input  {
    .form-control {
        width: 100%;
        &:focus {
            box-shadow: none;
    border-color: $warning;
        }
    }
    .flag-dropdown {
        background-color: #162731;
        border-color: #4B4B4B;
        &:focus {
            border-color: #4B4B4B;
        }
        .country-list {
            background-color: #283F46;
            border-color: #4B4B4B;
            .country{
                &:hover{
                    box-shadow: none;
                    border-color: $warning;
                    background-color: rgba($color: $warning, $alpha: 0.12);
                    color: $warning;
                }
                
            }
            .country.highlight{
                background-color: $orange-link;
                opacity: 0.9;
                color: $white;
            }
            .search{
                background-color: #283F46; 
                .search-box{
                    background-color: #162731;
                    color: $white;
                    border-color: $warning;
                    width: 90%;
                }
            }
          }
          .selected-flag{
            background-color: #162731;
            border: unset;
          }
          .selected-flag .open{
            background-color: #162731;
            border: unset;
          }
      }
      .flag-dropdown.open {
        background: unset;
    }
    
    .flag-dropdown.open .selected-flag {
        background-color: #162731;
    }
  }
  
 
