.activity-create-modal {
    @media (max-height: 768px) {

        form {
            margin-bottom: 5rem !important;
        }

        .action-line {
            display: flex;
            width: auto;
            padding: 0;
            position: fixed;
            bottom: 0.5rem;
            left: 1.5rem;
        }

    }
}