
  .confirmation-modal{

      .modal-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;
            padding: 3rem 4rem;
            .primary-header{
                font-size: 3rem;
                font-weight: 700;
                color: #FF9F43;
        
            }
            .header{
                font-size: 1.35rem;
                font-weight: 500;
                color: #D0D2D6;
            }
            .subheader{
                font-size: 0.875rem;
                font-weight: 500;
                color: #B4B7BD;
            }
        }
          
  }
