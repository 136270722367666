@import '../bootstrap-extended/include'; // Bootstrap includes
@import '../components/include'; // Components includes



.backToLogin {
  margin: 21px 0 0 12px;
  color: $orange-link;

  > a {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $orange-link;
  }
}
.auth-wrapper.auth-basic .auth-inner:before{
    right: -125px !important;
    top: -194px !important ;
}
.auth-wrapper.auth-basic .auth-inner:after{
    
    left: -147px !important ;
    bottom: -58px !important ;
}