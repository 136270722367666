.dragged-activity-modal {
    width: fit-content;
    .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        padding: 3rem 4rem;

        .custom-swal-icon {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .header {
            font-size: 1.35rem;
            font-weight: 500;
            color: #D0D2D6;
        }

        .subheader {
            font-size: 0.875rem;
            font-weight: 500;
            color: #B4B7BD;
            text-align: center;
            .importance{
                color: #FF9F43;
            }
        }

        .buttons{
            display: flex;
            justify-content: center;
            column-gap: 0.8rem;
        }
    }

}