.privacy-policy-container {
    color: antiquewhite;
    padding: 1rem;
    line-height: 1.4rem;
    border: 1px solid antiquewhite;

    .history-button {
        text-decoration: underline;
        cursor: pointer;
    }
}